.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ski-name {
  margin-bottom: 1rem;
}

.ski-container {
  display: inline-flex;
  margin: 20px;
  margin: 5rem;
}

.ski-container {
  text-align: center;
}

.ski-container img {
  max-width: 100%;
  max-height: 100%;
  border: 15px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7),  rgba(255, 255, 255, 0.3));
}

.skipic {
  margin: 0.2rem;
  margin-right: 1rem;
  height: 500px;
  width: 1500px;
}

.remove-linkbar {
  color: white;
  text-decoration: none;
}

.ski-container h3 {
  font-size: 2.5rem;
  margin-bottom: 0;
  vertical-align: top;
  color:rgba(255, 255, 255, 0.7)
}

.ski-container h5 {
  margin-top: 0;
  font-size: 1.2rem;
  color:rgba(255, 255, 255, 0.7)
}

.description-ski-container {
  color: rgba(255, 255, 255, 0.7);
  text-align: left;
  margin-left: 10%;
}

.description-ski-container h1 {
  margin-top: auto;
}

.description-ski-container p {
  text-align: left;
  color: rgba(255, 255, 255, 0.7);
  max-width: 450px;
  height: auto;
}

.current {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  opacity: 85%;
}

@media (max-width: 880px) {
  .projects-container {
    display: block;
    margin: 5%;
  }

  .image-container {
    text-align: left;
    margin-bottom: 2rem;
  }

  .description-ski-container {
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
    margin-left: 0;
    margin-bottom: 2rem;
  }
}