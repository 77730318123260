.contact-container {
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    margin-top: 50px;
  }
  
  .contact-container h1 {
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }

  .is-invalid {
    border: 2px red solid;
  }
  
  .invalid-feedback {
    color: red;
  }
  
  label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  input[type='text'],
  textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
  }
  
  textarea {
    resize: vertical;
  }
  
  button[type='submit'] {
    background-color: #f6f6f6;
    color: #3e3e3e;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  