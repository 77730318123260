* {
    box-sizing: border-box;
    margin:0;
    padding:0;
  }

h2 {
  font-size: 1.5rem;
  color:rgba(255, 255, 255, 0.7);
}

h3, p {
  color: #3e3e3e;
}

.icon {
  margin: 0.2rem;
  margin-right: 1rem;
  height: 100px;
  width: 100px; 
  border-radius: 25%;
}

.box {
  overflow: auto;
}

.wrapper {
  position: relative;
  display: flexbox;
  justify-content: center;
  align-items: center;
}

.content-container {
  margin: 50px;
}

.warning-container {
  margin: auto;
  margin-bottom: 50px;
  width: 90%;
  border: 15px solid;
  border-image-slice: 1;
  border-width: 5px;
  background-color: #f6f6f686;
  border-image-source: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7),  rgba(255, 255, 255, 0.3));
  padding: 10px;
}

.warning-container .text-warn {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  color: #3e3e3e;
  font-size: 25px;
}

.text-warn span{
  position: absolute;
  right: 0;
  margin-top: 5px;
}

.work-container h2 {
  margin-bottom: 1rem;
}

.open-source-container {
  margin-bottom: 5%;
}

.work-container a {
  display: inline-flex;
  border: 15px solid;
  border-image-slice: 1;
  border-width: 5px;
  background-color: #f6f6f686;
  border-image-source: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7),  rgba(255, 255, 255, 0.3));
  padding: 10px;
  width: 100%;
  height: auto;
}

.Website, .GDG, .MarkManager {
  color: black;
  text-decoration: none;
}

@media (min-width: 1280px) {
  .content-container {
    margin-left: 20rem;
    margin-right: 20rem;
  }
}

@media (max-width: 680px) {
  .warning-container {
    width: 90%;
  }

  .work-container a {
    width: 100%;
  }

  .icon {
    margin: 0.2rem;
    margin-right: 1rem;
    height: 48px;
    width: 48px; 
  }
}